.main-content-auth {
  .card {
    border-color: $gray-3 !important;
  }

  .form-control {
    height: 40px;
    border-radius: 8px;

    &:not(.is-invalid) {
      border-color: $gray-3;
    }

    &::placeholder {
      color: $gray-6;
    }
  }
}
