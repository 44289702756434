.badge-vars {
  padding-inline-start: 0;

  li {
    list-style: none;

    span {
      text-transform: lowercase;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
.badg-round {
  $d: 13px;
  background-color: $primary;
  color: $body-color;
  font-size: 8px;
  line-height: $d;
  height: $d;
  min-width: $d;
  text-align: center;
  font-weight: bold;
  border-radius: $d / 2;
  padding: 0 2px;
}
.notification-center-button {
  position: relative;
  .th-icon {
    font-size: 24px;
  }
  .badg-round {
    position: absolute;
    top: -14px;
    right: -10px;
  }
}
