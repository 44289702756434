@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('../../fonts/tradegothic/TradeGothicLTStd.eot');
  src: url('../../fonts/tradegothic/TradeGothicLTStd.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/tradegothic/TradeGothicLTStd.woff2') format('woff2'),
      url('../../fonts/tradegothic/TradeGothicLTStd.woff') format('woff'),
      url('../../fonts/tradegothic/TradeGothicLTStd.ttf') format('truetype'),
      url('../../fonts/tradegothic/TradeGothicLTStd.svg#TradeGothicLTStd') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('../../fonts/tradegothic/TradeGothicLTStd-Bd2.eot');
  src: url('../../fonts/tradegothic/TradeGothicLTStd-Bd2.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/tradegothic/TradeGothicLTStd-Bd2.woff2') format('woff2'),
      url('../../fonts/tradegothic/TradeGothicLTStd-Bd2.woff') format('woff'),
      url('../../fonts/tradegothic/TradeGothicLTStd-Bd2.ttf') format('truetype'),
      url('../../fonts/tradegothic/TradeGothicLTStd-Bd2.svg#TradeGothicLTStd-Bd2') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
