.dashboard-calendar-sticky {
  background-color: white;
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: auto;
  position: sticky;
  top: 40px;

  .spacer {
    height: 150px;
  }
}
.timeline-sticky {
  position: sticky;
  top: 0;
  z-index: 3;
}