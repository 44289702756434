.card-app-default {
  background-color: transparent;
  box-shadow: none;

  .card-header {
    background-color: $gray-4;
    font-weight: normal;
    border-radius: $card-border-radius !important;
    border-bottom: 0;
  }

  .card-body {
    background-color: $gray-100;
    border: solid $gray-4;
    border-width: 0 1px 1px;
  }
}

.card-sm {
  .card-header,
  .card-body {
    padding: $card-spacer-y-sm $card-spacer-x-sm;
  }
}
.card {
  &.card-sec-top {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.card-sec-bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
