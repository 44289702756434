.navbar-brand {
  .logo {
    width: auto;
    height: 32px;
    display: block;
  }
}
@media screen and (max-width: 1320px) {
  .navbar-brand {
    min-width: auto !important;
    .logo {
      height: 20px;
    }
  }
}
