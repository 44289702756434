.app-loading {
  position: fixed !important;
  z-index: 10001;
  left: 50%;
  top: 50%;

  &:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.app-component-loading {
  &:before {
    content: " ";
    width: 100%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 20000;
  }

  &:after {
    content: " ";
    position: absolute;
    display: inline-block;
    width: $spinner-width;
    height: $spinner-height;
    vertical-align: text-bottom;
    border: $spinner-border-width solid $nomads;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
    position: absolute;
    left: 50%;
    top: 80%;
  }
}

.app-component-loading-100 {
  &:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 20000;
  }

  &:after {
    content: " ";
    position: absolute;
    display: inline-block;
    width: $spinner-width;
    height: $spinner-height;
    vertical-align: text-bottom;
    border: $spinner-border-width solid $nomads;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;
  }
}
