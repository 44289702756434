.uppercase {
  text-transform: uppercase;
}

.error {
  color: $form-feedback-invalid-color;
  font-size: 80%;
}

.success {
  color: $success;
  font-size: 80%;
}

.cursor-pointer {
  cursor: pointer;
}

.divider-dashed {
  border-top-style: dashed;
  margin: 1rem 0;
}

.circle-primary-small {
  width: 9px;
  height: 9px;
  background: $nomads;
  border-radius: 50%;
  display: inline-block;
}

.circle-outline-primary-small {
  width: 9px;
  height: 9px;
  border: 1px solid $nomads;
  border-radius: 50%;
  display: inline-block;
}

.circle-outline-default-small {
  width: 9px;
  height: 9px;
  border: 1px solid $gray-default;
  border-radius: 50%;
  display: inline-block;
}

.app-separator {
  display: inline-flex;
  align-items: center;

  &-line {
    min-width: 19px;
    display: inline-block;
    border-bottom: 1px solid $nomads;
  }

  &-default-line {
    min-width: 19px;
    display: inline-block;
    border-bottom: 1px solid $gray-default;
  }
}

.overflow-auto {
  overflow: auto;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.b-top {
  border-top: 1px solid $gray-13;
}
.b-bottom {
  border-bottom: 1px solid $gray-13;
}
.b-left {
  border-left: 1px solid $gray-13;
}
.b-right {
  border-right: 1px solid $gray-13;
}
.radius-0 {
  border-radius: 0 !important;
}
.no-shadow {
  box-shadow: none !important;
}
