@font-face {
  font-family: 'Analogue';
  src: url('../../fonts/analogue/AnalogueRegular.eot');
  src: url('../../fonts/analogue/AnalogueRegular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/analogue/AnalogueRegular.woff2') format('woff2'),
      url('../../fonts/analogue/AnalogueRegular.woff') format('woff'),
      url('../../fonts/analogue/AnalogueRegular.ttf') format('truetype'),
      url('../../fonts/analogue/AnalogueRegular.svg#AnalogueRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

