.aside {
  background-color: #fff;
  height: 100%;
  padding: 50px;

  h2 {
    margin-bottom: 1.4em;
  }

  .aside-list {
    padding-left: 10px;
    list-style: inside none none;

    li {
      margin-bottom: 1.3em;
      position: relative;
      &:before {
        position: absolute;
        top: 10px;
        left: -29px;
        content: "\ea06";
        font-family: "threeangular" !important;
        font-size: 14px;
        line-height: 1;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    h3 {
      margin-bottom: 0.2em;
    }
  }
}
