.icon-alert {
  position: relative;

  &:after {
    display: block;
    position: absolute;
    content: "";
    right: -7px;
    top: -2px;
    z-index: 100;
    width: 6px;
    height: 6px;
    background-color: $pink;
    border-radius: 50%;
  }
}

$owner-table-p: 7px;

.owner-table-name {
  display: inline-block;
  padding-right: $owner-table-p + 2px;
}
.owner-table-icons {
  display: inline-block;
}
.owner-table-icon {
  display: inline-block;
  line-height: 15px;
  padding-right: $owner-table-p;

  .icon-key {
    font-size: 80%;
    opacity: 0.7;
    margin-right: 1px;
  }

  & + .owner-table-icon {
    border-left: 1px solid #ccc;
    padding-left: $owner-table-p;
  }
}
