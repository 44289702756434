.sidemenu {
  .list-group-item {
    color: $gray-2;
    font-size: $font-size-sm;

    &:hover {
      color: $list-group-hover-color;
    }

    &.active {
      color: $list-group-active-color !important;
    };
  }
}
