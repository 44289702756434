.payment-info {
  position: relative;
  margin: 0 auto;
  border-bottom: 1px solid #ddd;
  padding: 0;

  table {
    width: 100%;

    th,
    td {
      padding: 4px 15px;
    }

    thead {
      border-top: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;
      background-color: #f9f9f9;

      th {
        text-transform: uppercase;
        font-weight: normal;
        font-size: 16px;
      }
    }

    th,
    .text-th {
      font-weight: bold;
    }

    .th-percent {
      width: 100px;
    }

    .table-text-end {
      text-align: right;
    }
  }
}
