.bg-app-dark {
  background-color: $dark-100;
}

.bg-dark-1 {
  background-color: $dark-1;
}

.bg-yellow-1 {
  background-color: $yellow-1;
}

.bg-yellow-4 {
  background-color: $yellow-4;
}

.bg-gray-3 {
  background-color: $gray-3;
}

.bg-gray-4 {
  background-color: $gray-4;
}

.bg-pink-1 {
  background-color: $pink-1;
}
.bg-light-red {
  background-color: rgba($red,.3);
}

.no-main-color {
  color: unset;
}

.text-app-white {
  color: $white;

  &:hover {
    color: $white !important;
  }
}

.text-gray-1 {
  color: $gray-1;

  &:hover {
    color: $gray-1;
  }
}

.text-gray-2 {
  color: $gray-2;

  &:hover {
    color: $gray-2;
  }
}

.text-gray-3 {
  color: $gray-3;

  &:hover {
    color: $gray-3;
  }
}

.text-gray-5 {
  color: $gray-5;

  &:hover {
    color: $gray-5;
  }
}

.text-gray-6 {
  color: $gray-6;

  &:hover {
    color: $gray-6;
  }
}

.text-gray-8 {
  color: $gray-8;

  &:hover {
    color: $gray-8;
  }
}

.text-gray-9 {
  color: $gray-9;

  &:hover {
    color: $gray-9;
  }
}

.text-gray-10 {
  color: $gray-10;

  &:hover {
    color: $gray-10;
  }
}

.text-gray-800 {
  color: $gray-800;

  &:hover {
    color: $gray-800;
  }
}

.text-green-1 {
  color: $green-1;

  &:hover {
    color: $green-1;
  }
}

.text-green-2 {
  color: $green-2;

  &:hover {
    color: $green-2;
  }
}

.text-green-3 {
  color: $green-3;

  &:hover {
    color: $green-3;
  }
}

.text-green-5 {
  color: $green-5;

  &:hover {
    color: $green-5;
  }
}

.text-blue-1 {
  color: $blue-1;

  &:hover {
    color: $blue;
  }
}

.text-red-1 {
  color: $red-1;

  &:hover {
    color: $red-1;
  }
}

.text-red-2 {
  color: $red-2;

  &:hover {
    color: $red-2;
  }
}

.text-pink-1 {
  color: $pink;

  &:hover {
    color: $pink;
  }
}

.text-dark-1 {
  color: $dark-1;

  &:hover {
    color: $dark-1;
  }
}

.text-dark-2 {
  color: $dark-2;

  &:hover {
    color: $dark-2;
  }
}

.text-yellow-3 {
  color: $yellow-3;

  &:hover {
    color: $yellow-3;
  }
}
