.dropzone {
  background-color: $dropzone-container-bg;
  border: 1px dashed $dropzone-container-border;
  border-radius: $dropzone-container-border-radius;
  text-align: center;
  color: $dropzone-color;
  cursor: pointer;
  display: flex;
  justify-content: center;

  .dz-remove-single {
    position: absolute;
    cursor: pointer;
    right: 2px;
    top: 2px;
    background-color: $gray-500;
    color: $gray-700;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .75rem;
  }

  .dz-message {
    border: 0;
    padding: 0 1rem;
    margin: 0;
    overflow: auto;
    z-index: unset;

    p {
      font-size: $font-size-sm;
    }
  }
}
