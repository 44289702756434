.dshift {
  &.cell {
    border-top: 1px solid $gray-13;
  }

  .slot {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &--assigned {
      background-color: $gray-12;
    }

    &--accepted {
      background-color: #9acf99;
    }

    &--pending {
      background-color: #f9d986;
    }

    &--rejected {
      background-color: #c26b6b;
      color: white;
    }

    .team {
      background-color: $primary;
      color: $body-color;
      border-radius: 4px;
      padding: 4px 8px;
    }
    .empty {
      text-align: center;
      color: $gray-11;
    }

    .icon-delete {
      visibility: hidden;
    }
    &:hover {
      .icon-delete {
        visibility: visible;
      }
    }
  }
}

.dshift.cell {
  min-width: 200px;
  &--pointer {
    cursor: pointer;
  }
}

.dshift-task-icon {
  background-color: rgba(0, 0, 0, 0.73);
  color: #fff;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  border-radius: 9px;
}

.mshift {
  &--assigned {
    background-color: $gray-12;
  }

  &--accepted {
    background-color: #9acf99;
  }

  &--pending {
    background-color: #f9d986;
  }

  &--rejected {
    background-color: #c26b6b;
    color: white;
  }
}
