.travel-report {
  background-color: #f4f4f4;
  margin-bottom: 20px;
  padding-bottom: 20px;

  .info-column{
    background-color: #ffffff;
    margin:15px 0;
    padding: 15px;
    -webkit-box-shadow: 0px 0px 9px -2px rgba(150,150,150,0.32);
    -moz-box-shadow: 0px 0px 9px -2px rgba(150,150,150,0.32);
    box-shadow: 0px 0px 9px -2px rgba(150,150,150,0.32);
  }

  .images img{
    border-radius: 3px;
    margin-bottom: 10px;
  }

  .actions{
    margin-top: 15px;
  }

  .traveler-info{
    font-size: 13px;
    line-height: 25px;

    .info-field{
      font-weight: 600;
    }

    hr{
      margin: 20px 0;
    }
  }

  h1{
    font-size: 35px;
  }
}