.horizontal-menu {
  margin-left: -18px;
  margin-right: -18px;

  .scroll-menu-arrow {
    padding: 5px;
    cursor: pointer;

    &--disabled {
      visibility: hidden;
    }
  }

  .arrow-prev,
  .arrow-next {
    background-color: #fff;
    display: block;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  .menu-item-wrapper {
    //   border: 1px solid $gray-300;
    //   border-right-width: 0;

    //   &:last-child {
    //     border-right-width: 1px !important;
    //   }

    //   .btn  {
    //     border: 0;
    //     box-shadow: none;
    //     background-color: transparent;
    //     border-radius: 0;
    //     color: $gray-2;
    //     font-weight: normal;

    //     &.selected {
    //       color: $primary;
    //       background-color: $gray-7;
    //     }

    //     &:active {
    //       background-color: transparent !important;
    //     }

    //     &:hover {
    //       transform: none;
    //     }
    //   }
    padding: 0 16px;
    .menu-item-link {
      display: block;
      font-size: 16px;
      font-weight: bold;
      line-height: 48px;
      height: 50px;
      color: $body-color;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &.active {
        border-bottom-color: $body-color;
      }
    }
  }

  .menu-wrapper--inner {
    .menu-item-wrapper:first-child {
      padding-left: 0;
    }
    .menu-item-wrapper:last-child {
      padding-right: 0;
    }
  }
}
