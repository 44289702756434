.kanban-board {
  width: 100%;
  overflow-x: auto;
}
.kanban-row {
  display: flex;
  align-items: stretch;
  gap: 16px;
  padding-bottom: 10px;
}
.kanban-col {
  width: 300px;
  background-color: #f7f7f7;
  //overflow: hidden;

  border: 1px solid rgba(0, 0, 0, 0.06);

  transition: width 0.3s ease-in-out;

  &.is-hovered {
    border: 1px dashed #888;
  }
}
.kanban-col_header {
  background-color: #ddd;
  padding: 0 12px;
  width: 300px;
  height: 40px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  transform-origin: 0 0;

  transition: transform 0.3s ease-in-out;

  .kanban-col_header-title {
    text-transform: uppercase;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .kanban-col_header-num {
    font-weight: bold;
    font-size: 12px;
    color: #fff;
    background-color: #888;
    height: 18px;
    line-height: 18px;
    min-width: 18px;
    text-align: center;
    padding: 0 3px;
    border-radius: 50%;
    transform: rotate(-90deg);
    display: none;
  }
  .kanban-col_header-button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    aspect-ratio: 1/1;
    transition: transform 0.3s ease-in-out;
    border-radius: 9999px;

    &:hover {
      background: rgba(0, 0, 0, 0.212);
    }
  }
}
.kanban-col_body {
  // width: 300px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
}

.kanban-item {
  background-color: #ddd;
  border-radius: 6px;
  padding: 10px;

  &.is-dragging {
    opacity: 0.1;
  }
}
.kanban-col_create-button {
  border: none;
  outline: none;
  background-color: #eee;
  border-radius: 6px;
  padding: 10px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  font-size: 12px;
  color: #888;
  cursor: pointer;
  transition: color 0.1s;

  &:hover {
    color: #111;
  }
}

.kanban-item-title {
  font-weight: bold;
  display: block;

  &:hover {
    text-decoration: underline;
  }
}

.kanban-item-delete {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 10px;
  color: #888;
  cursor: pointer;
  transition: color 0.1s;

  &:hover {
    color: #111;
  }
}
//

.kanban-col.minimized {
  width: 42px;
  min-height: 300px;
  background-color: #ddd;

  .kanban-col_header {
    width: fit-content;
    transform: translateX(40px) rotate(90deg);

    .kanban-col_header-num {
      display: block;
    }

    .kanban-col_header-button {
      transform: rotate(-90deg);
    }
  }

  .kanban-col_body {
    display: none;
  }
}
