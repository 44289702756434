//
// Icon buttons
//

// General styles

.mt-n1 {
  margin-top: -0.25rem !important;
}

.btn {
  position: relative;
  text-transform: $btn-text-transform;
  transition: $transition-base;
  letter-spacing: $btn-letter-spacing;
  font-size: $input-btn-font-size;
  will-change: transform;

  &:hover {
    @include box-shadow($btn-hover-box-shadow);
    transform: translateY($btn-hover-translate-y);
  }

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  // Icons

  i:not(:first-child),
  svg:not(:first-child) {
    margin-left: 0.5rem;
  }

  i:not(:last-child),
  svg:not(:last-child) {
    margin-right: 0.5rem;
  }
}
.btn-primary {
  &,
  &:hover,
  &:active,
  &:focus {
    color: $body-color;
  }
}
.btn-outline-primary {
  &,
  &:hover,
  &:active,
  &:focus {
    color: $body-color;
    border-color: $body-color !important;
  }
}

// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
  .btn {
    margin-right: 0;
    transform: translateY(0);
  }
}

// Size variations

.btn-sm {
  font-size: $input-btn-font-size-sm;
}

.btn-lg {
  font-size: $input-btn-font-size-lg;
}

// Some quick fixes (to revise)

// Fixes
[class*="btn-outline-"] {
  border-width: 1px;
}

.btn-outline-secondary {
  color: darken(theme-color("secondary"), 50%);
}

.btn-inner--icon {
  i:not(.fas):not(.fab) {
    position: relative;
    top: 2px;
  }
}

.btn-link {
  font-weight: $btn-font-weight;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    transform: none;
  }
}

.btn-primary-link {
  color: $primary;
  background-color: transparent;
  font-weight: $btn-font-weight;
  box-shadow: none;
  &:active {
    box-shadow: none;
    transform: none;
  };
  &:focus {
    box-shadow: none;
    transform: none;
  };
  &:hover {
    box-shadow: none;
    transform: none;
  }
}

.btn-neutral {
  color: theme-color("primary");
}

.btn-datepicker-custom {
  min-width: 240px;
  margin-bottom: 0;
  .input-group {
    width: 240px;
  }
  input {
    min-width: 160px;
    width: auto;
    max-width: 200px;
    margin-left: auto;
  }

  &.nobordered {
    input {
      background-color: transparent !important;
      border: none !important;
      padding-right: 0 !important;
    }
    .input-group-text {
      background-color: transparent;
      border: none !important;
      padding-left: 0 !important;
    }
  }
}
