.badge-default-outline {
  border: 1px solid $gray-500;
  color: $gray-500;
  font-weight: normal;
  text-transform: none;
  padding: 0.25rem $badge-pill-padding-x;
}

.badge-green-2 {
  background-color: $green-2;
  color: $white;
}

.badge-green-3 {
  background-color: $green-3;
  color: $white;
}

.badge-green-6 {
  background-color: $green-6;
  color: $white;
}

.badge-red-1 {
  background-color: $red-1;
  color: $white;
}

.badge.clickeable {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.badge-pill {
  line-height: 1 !important;
  padding: 0.8rem 1.5rem;
  border-radius: 12rem;
}
