.map-container {
  width: 100%;
  height: calc(100vh - #{$navbar-nav-height} - #{$navbar-subnav-height} - 57px);
}

.marker {
  cursor: pointer;
  position: absolute;
  left: -5px;
  top: -5px;

  &-circle {
    width: 10px;
    height: 10px;
    background-color: $red-3;
    border-radius: 50%;
  }

  &-avatar {
    position: absolute;
    top: -41px;
    left: -15px;
    border-radius: 50%;
    @include avatar(40px);

    img, &-initials {
      @include avatar(40px);
      border-radius: 50%;
      border: 4px solid $white;
    }

    &-initials {
      color: #fff;
      background-color: $gray-500;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      border-radius: 50%;
    }

    &::after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: $white transparent transparent transparent;
      content: "";
      position: absolute;
      bottom: -6px;
      left: 11.5px;
    }
  }

  &-icon {
    position: absolute;
    background-color: $red-2;
    color: $white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: 2px solid $white;
    right: -13px;
    top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
  }
}
