//
// Custom toggle
//

.custom-toggle-container {
  display: inline-block;
  width: $custom-toggle-width + 50px;

  .custom-toggle {
    $p: 25px;
    position: relative;
    display: inherit;
    width: $custom-toggle-width;
    height: $custom-toggle-height;
    margin: 0 $p;
    display: inline-block;

    .custom-toggle-slider_label-left,
    .custom-toggle-slider_label-right {
      position: absolute;
      top: 2px;
      font-size: 14px;
      line-height: 1;
    }
    .custom-toggle-slider_label-left {
      left: -$p;
    }
    .custom-toggle-slider_label-right {
      right: -$p + 2px;
    }

    input {
      display: none;

      &:checked {
        + .custom-toggle-slider {
          // border: $custom-control-indicator-border-width solid
          //   $custom-control-indicator-checked-border-color;
          background-color: $primary;

          &:before {
            background: darken($primary, 50);
            transform: translateX($custom-toggle-width - $custom-toggle-height);
          }
        }
      }

      &:disabled {
        + .custom-toggle-slider {
          // border: $custom-control-indicator-border-width solid
          //   $custom-control-indicator-disabled-bg;
        }

        &:checked {
          + .custom-toggle-slider {
            border: $custom-control-indicator-border-width solid
              $custom-control-indicator-disabled-bg;

            &:before {
              background-color: lighten(
                $custom-control-indicator-checked-bg,
                10%
              );
            }
          }
        }
      }
    }
  }

  &.hide-side-labels {
    width: $custom-toggle-width;
    .custom-toggle {
      margin: 0;
    }
  }
}
.custom-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: $custom-control-indicator-border-width solid
    $custom-toggle-border-color;
  border-radius: 34px !important;
  background-color: #eaeaea;

  &:before {
    position: absolute;
    content: "";
    height: $custom-toggle-height;
    width: $custom-toggle-height;
    left: 0px;
    bottom: 0px;
    border-radius: 50% !important;
    background-color: $custom-toggle-slider-bg;
    transition: $input-transition;
  }
}

.custom-toggle-wrapper {
  .custom-toggle + .custom-toggle {
    margin-left: 1rem !important;
  }
}

// Color variations

@each $color, $value in $theme-colors {
  .custom-toggle-#{$color} {
    @include custom-toggle-variant($value);
  }
}
