.custom-checkbox-white {
  .custom-control-label {
    &::before {
      border: 1px solid $input-border-color !important;
    }
  }

  .custom-control-input {
    &:active ~.custom-control-label::before {
      background-color: $white;
    }
  }
}
