.dropdown-item {
  padding: .2rem 1rem !important;
}

.dropdown .btn-link {
  color: $gray-700 !important
}

.dropdown-actions {
  .dropdown-item {
    color: $gray-700;
  }

  &.dropdown-menu {
    transition: visibility 0s, opacity 0.2s linear;
  }
}
