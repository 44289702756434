.navbar-top.main-navbar {
  height: $navbar-nav-height;
  padding: 0;
  @include media-breakpoint-down(md) {
    height: $navbar-nav-height-mobile;
  }
  .navbar-brand {
    height: 100%;
    display: flex;
    align-items: center;
    min-width: $navbar-vertical-open-width;
    position: absolute;
    right: 0;
    left: 0;
    justify-content: center;
    z-index: -1;
    @include media-breakpoint-up(lg) {
      position: static;
      justify-content: start;
      padding-left: $navbar-nav-padding-x;
    }

    p {
      max-width: $navbar-vertical-open-width;
    }
  }

  .navbar-search {
    background-color: transparent;

    .input-group-text {
      color: white;
    }

    input {
      color: white;
      font-weight: bold;
      font-size: $font-size-sm;
    }

    input::placeholder {
      color: $gray-400;
      font-weight: normal;
      font-style: normal !important;
      font-size: $font-size-xsm;
    }
  }

  .nav-link {
    cursor: pointer;

    .avatar-sm {
      width: 25px;
      height: 25px;
    }

    i {
      font-size: 1rem;
    }
  }

  .navbar-nav {
    a.active {
      p {
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

  .navbar-dropdown {
    .dropdown-item {
      font-size: $font-size-xsm;
    }

    .dropdown-title {
      font-size: $font-size-xsm;
      font-weight: bold;
      border-bottom: 1px dotted $gray-200;
      padding: 0.5rem 0;
      margin: 0 1rem 0.5rem;
    }

    .dropdown-divider {
      border-top-style: dotted;
      border-top-color: $gray-200;
      margin: 0.3rem 0;
    }

    .dropdown-item:hover {
      background-color: $gray-100;
    }
  }
}

.navbar-top.subnavbar {
  top: $navbar-nav-height;
  height: $navbar-subnav-height;
  padding: 0 $navbar-subnav-padding-x !important;
  background-color: white;
  border-bottom: 0 !important;
  z-index: 1000;

  .nav {
    height: 100%;

    .nav-item {
      .nav-link {
        height: 100%;
        color: $dark-555;
        font-size: $font-size-sm;
        text-transform: uppercase;
      }

      &.active .nav-link {
        color: $nomads;
        border-bottom: 3px solid $nomads;

        .nav-link-text {
          padding-top: 3px;
        }
      }
    }

    .dropdown-menu {
      margin-top: 1px;

      .dropdown-divider {
        border-top-style: dotted;
      }

      .dropdown-item.active {
        color: $nomads;
      }
    }
  }
}
.navbar-vertical .navbar-nav .nav-link {
  align-items: flex-start;
}

.auth-navbar {
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.sidenav {
  margin-top: $navbar-nav-height-mobile;

  @include media-breakpoint-up(lg) {
    transform: unset;
    max-width: $navbar-vertical-collapse-width !important;
    margin-top: $navbar-nav-height;

    &.fixed-left ~ .main-content {
      margin-left: $navbar-vertical-collapse-width;
      @include transition($transition-base);
    }
  }

  .navbar-nav {
    & > .nav-item {
      padding: 0.4rem 0;
      margin-top: 0;
      // &:not(:last-child) {
      //   border-bottom: 1px solid #e8e8e8;
      // }
    }
  }
}

.sidenav-wrapper-items {
  overflow-y: auto;
  max-height: 100%;
}
.sidenav-collapse {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  right: 0px;
  display: none;
}
@include media-breakpoint-up(lg) {
  .sidenav:hover .sidenav-collapse {
    display: block;
  }
}
.sidenav-collapse .btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  top: 100px;
  right: -15px;
}

.g-sidenav-hidden:not(.g-sidenav-show) .sidenav,
.g-sidenav-show .sidenav {
  .nav-link-text {
    display: block !important;
  }

  .nav-item .collapse.show {
    display: flex !important;
  }

  .navbar-nav {
    & > .nav-item {
      & > .nav-link[data-toggle="collapse"] {
        // padding: $navbar-vertical-nav-link-padding-y
        //   $navbar-vertical-nav-link-padding-x !important;

        &:after {
          // content: "\e828";
          // font-size: 5px;
          // font-family: Fontello;
          display: none;
        }

        // &[aria-expanded="true"]:after {
        //   transform: rotate(180deg);
        // }
      }
    }
  }
}
.navbar-light .navbar-nav .nav-link {
  color: $body-color;
}
// .nav-item.nav-divisor {
//   width: 2px;
//   height: 35px;
//   background-color: rgba(255, 255, 255, 0.288);
//   margin: 0 !important;
//   padding: 0 !important;
// }
.sidebar-graph-container {
  flex: 0 0 58px;
  padding-top: 7px;
  padding-right: 7px;
  width: 58px;
  line-height: 22px;
  height: 22px;
  visibility: hidden;
  color: $secondary;
  display: flex;
  align-items: center;

  .sidebar-graph-container__line {
    flex: 1 0 auto;
    background-color: $secondary;
    height: 1px;
  }
  i {
    position: relative;
    left: -1px;
  }
}

.nav-item.active,
.nav-item .text-secondary {
  .sidebar-graph-container {
    visibility: visible;
  }
}
.nav-link.is-open {
  & > .sidebar-graph-container {
    visibility: visible;
  }
}

.sidebar-subnav {
  .nav-link {
    span {
      position: relative;
      &:after {
        content: "|";
        position: absolute;
        top: 0;
        left: -8px;
        // width: 1px;
        // height: 25px;
        display: block;
        visibility: hidden;
        //background-color: $secondary;
      }
    }
    &.sec-active {
      span {
        &:after {
          visibility: visible;
        }
      }
    }
  }
  .sidebar-graph-container {
    visibility: hidden !important;
    flex: 0 0 75px;
  }
}
.nav-item.nav-divisor {
  width: 2px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.288);
  margin: 0 !important;
  padding: 0 !important;
}
