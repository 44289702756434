.editor-preview {
  color: black;
}
.editor-preview pre {
  font-family: inherit;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.rotate-down {
  transform: rotate(90deg);
}

.transition-all {
  transition: all 0.2s;
}
