.coming-soon {
  margin-top: 100px;
  text-align: center;

  img{
    max-height: 350px;
  }

  h1{
    font-size: 35px;
  }
}