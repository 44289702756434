.custom-select-container {
  padding: 0;
  height: auto;
}

.custom-select-container-higher {
  z-index: 3000;
}

.custom-select__indicator,
.css-tlfecz-indicatorContainer {
  cursor: pointer;
  line-height: 1 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.custom-select {
  padding: 0;

  &--is-disabled {
    background-color: #e9ecef;
  }

  &-creatable {
    overflow-y: auto;
    flex: 1 1;

    .custom-select__control {
      padding: 0 $input-padding-x;
      min-height: $input-height;
      font-size: $input-btn-font-size;
      line-height: $input-line-height;
      border-radius: $input-border-radius;
      color: $input-color;
      background-color: $input-bg;
      background-clip: padding-box;
      border: $input-border-width solid $input-border-color;
      cursor: text;

      &--is-focused {
        border-color: $input-focus-border-color;
      }
    }

    &-sm .custom-select__control {
      min-height: $input-height-sm;
    //  padding: 0 $input-padding-x-sm;
    }

    &-lg .custom-select__control {
      min-height: $input-height-lg;
      padding: 0 $input-padding-x-lg;
    }

    .custom-select__indicator,
    .css-tlfecz-indicatorContainer {
      cursor: pointer;
      line-height: 1 !important;
      padding: 0 !important;
    }

    .custom-select__value-container {
      cursor: text;
    }

    .custom-select__dropdown-indicator {
      display: none;
    }

    .custom-select__multi-value__remove {
      cursor: pointer;
    }

    &.is-invalid {
      .custom-select__control {
        border-color: $form-feedback-invalid-color;
      }
    }
  }

  &__control {
    display: flex;
    align-items: center;
    height: 100%;
    background: transparent;
    padding: $input-padding-y $input-padding-x;
    padding-right: 0 !important;
  }

  &__value-container {
    padding: 0 !important;
    cursor: pointer;
  }

  &__single-value {
    margin-left: 0 !important;
    padding: 0 !important;
    color: $input-color !important;
    font-size: $font-size-sm !important;
  }

  &__placeholder {
    color: $input-placeholder-color !important;
    margin-left: 0 !important;
  }

  &__indicator-separator {
    width: 0 !important;
  }

  &__indicator {
    cursor: pointer;
  }

  &__multi-value-remove {
    cursor: pointer;
  }

  &__menu {
    z-index: 2000;
  }

  &__option {
    background-color: white !important;

    &--is-selected {
      background-color: $gray-100 !important;
      color: $gray-900 !important;
    }

    &:active,
    &:hover {
      background-color: $gray-200 !important;
    }
  }
}

.form-control-sm .custom-select {
  &__control {
    padding: calc(#{$input-padding-y-sm} - 8px) $input-padding-x-sm;
    min-height: $input-height-inner-sm;
  }

  &__indicator {
    padding: 5px 8px;
  }

  &__value-container--is-multi {
    padding: 0.25rem 0 !important;

    .custom-select__multi-value {
      margin: 0 2px 1px 0;

      &__label {
        padding: 2px 4px;
      }
    }
  }
}

.input-group.prepend .custom-select-creatable .custom-select__control {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group.append .custom-select-creatable .custom-select__control {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.prepend .form-control .custom-select__control {
  padding-left: 0 !important;
}
