.notification-center {
  font-size: 12px;
  height: 100%;
  .notification-center__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .notification-center__header {
    background-color: #fff;
  }
  .notification-center__close-button {
    cursor: pointer;
  }
  .notification-center__body {
    position: relative;
    overflow-y: auto;
    flex: 0 1 auto;
  }
  .notification-center__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.671);
  }

  .notification-center__not_notific {
    text-align: center;
    font-style: italic;
    padding: 10px;
  }

  .notification-center__item {
    border-bottom: 1px solid #ddd;
    position: relative;
    cursor: default;

    .notification-center__item-content {
      line-height: 1.3;
      padding: 7px 10px;
      //color: #748292;
      opacity: 0.6;
      &.unread {
        opacity: 1;
      }
    }
    .notification-center__item-circle {
      color: $primary;
      font-size: 6px;
      vertical-align: middle;
      margin-right: 3px;
    }
    .notification-center__item-title {
      font-weight: bold;
    }
    .notification-center__item-description {
      padding-bottom: 2px;
      .link-notification {
        padding: 4px 0;
      }
    }
    .notification-center__item-date {
      font-size: 10px;
      font-style: italic;
    }

    .notification-center__item-mark-as-read {
      font-size: 13px;
      padding: 1px 10px 10px 0;

      &.unread {
        .notification-center__item-mark-as-read-icon {
          color: $primary;
        }
      }
    }
  }
}

.tooltip {
  z-index: 12000;
}
.link-notification {
  color: $secondary;
  text-decoration: underline;
  font-weight: bold;
  display: inline-block;
}
