.input-group {
  .rdt .form-control {
    border-right: $input-border-width solid $input-border-color;
    padding-right: $input-padding-x;

    &.is-invalid {
      border-color: $form-feedback-invalid-color;
    }

    &.form-control-sm {
      padding-right: $input-padding-x-sm;
    }

    &.form-control-lg {
      padding-right: $input-padding-x-lg;
    }
  }

  &.focused .rdt .form-control {
    border-color: $input-focus-border-color;
  }

  &.clearable {
    &.focused .rdt-clear + .input-group-append {
      border-color: $input-focus-border-color;
    }
  }

  .rdt-clear {
    .input-group-text {
      color: hsl(0, 0%, 80%);

      &:hover {
        color: hsl(0, 0%, 60%);
      }
    }

    & + .input-group-append {
      border-left: $input-border-width solid $input-border-color;
    }
  }
}

.rdt {
  .rdtDay.rdtToday.rdtActive,
  .rdtDay.rdtActive,
  .rdtDay.rdtActive:hover {
    background-color: $primary !important;
  }

  .rdtPicker {
    display: none;
    padding: 1rem;

    .rdtTimeToggle {
      visibility: visible;
      font-size: 18px;

      &:before {
        visibility: hidden;
      }
    }
  }

  &.rdtOpen .rdtPicker {
    display: block;
  }

  .rdtCounters .rdtCounter:last-child .rdtCount {
    color: $primary;
    border-color: $primary;
    font-size: 0.9rem;
  }

  .form-control[readOnly] {
    background-color: white;
  }

  .form-control + .rdtPicker {
    top: $input-height;
  }

  .form-control-xsm + .rdtPicker {
    top: $input-height-xsm;
  }

  .form-control-sm + .rdtPicker {
    top: $input-height-sm;
  }

  .form-control-lg + .rdtPicker {
    top: $input-height-lg;
  }

  &.rdt-right {
    .rdtPicker {
      right: 0;
    }
  }
}
