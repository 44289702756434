.app-modal {
  .modal-content {
    border-radius: 2px;
  }

  .modal-body {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;

    &.overflow-visible {
      max-height: unset;
    }
  }
  &.modal-wide {
    max-width: 90%;
  }

  @include media-breakpoint-down(md) {
    .modal-body {
      max-height: unset;
    }
  }
}

.modal.show .modal-dialog {
  transform: none;
  animation: showModal 0.5s forwards !important;
}

@keyframes showModal {
  0% {
    opacity: 0;
    transform: translate(0, -50%);
  }
  100% {
    transform: none;
  }
}

@include media-breakpoint-up(lg) {
  .modal-xl {
    max-width: $modal-xl;
  }
}

.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 3000 !important;
  }
}
