.task-status-select {

  .custom-select {
    &__single-value {
      font-weight: bold;
      text-transform: uppercase;
    }

    &__input input {
      color: $white !important;
    }

    &__dropdown-indicator {
      color: $white;
    }

    &__single-value {
      color: $white !important;
    }
  }

  &.in_progress .custom-select {
    &-container {
      background-color: $blue-1;
      border-color: $blue-1;
    }
  }

  &.open .custom-select {
    &-container {
      background-color: $red-1;
      border-color: $red-1;
    }
  }

  &.assigned .custom-select {
    &-container {
      background-color: $gray-1;
      border-color: $gray-1;
    }
  }

  &.resolved .custom-select {
    &-container {
      background-color: $green-1;
      border-color: $green-1;
    }
  }

  &.closed .custom-select {
    &-container {
      background-color: $green-3;
      border-color: $green-3;
    }
  }
}

.dz-task-attachment {
  .dropzone {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, .34);
    background-color: $gray-100;
    width: 100px;
    height: 100px;
    border: 0;

    .dz-message {
      background-color: $gray-100;
      color: $gray-2;
    }
  }

  &-remove {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: $gray-500;
    color: $gray-700;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .5rem;
  }
}
