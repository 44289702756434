
.email-settings_logo {
  width: 100%;
  max-width: 200px;
  margin: auto;
  display: block;
}


.preview_container {
 border: 2px dashed rgba(0,0,0,0.2); 
 background: #f4f4f4
}

.preview_logo {
  max-width: 400px;
  width: 30%;
  max-height: 150px;
  margin: auto;
  display: block;
}

.preview_banner {
  width: 100%;
  height: 200px;
  
}

.icon-facebook {
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: block;
  background: url('../../../img/icons/social-network/facebook.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon-youtube {
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: block;
  background: url('../../../img/icons/social-network/youtube.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon-twitter {
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: block;
  background: url('../../../img/icons/social-network/twitter.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon-instagram-sketched {
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: block;
  background: url('../../../img/icons/social-network/instagram-sketched.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon-worldwide {
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: block;
  background: url('../../../img/icons/social-network/worldwide.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

