.daterange-picker {
  position: absolute;
  z-index: 1100;
  background-color: white;
  text-align: initial;
  min-width: 630px;
  margin-top: 5px;

  top:60px;
  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .DateRangePicker__CalendarSelection {
    background-color: $primary;
    border: 1px solid $primary;

    &--is-pending {
      background-color: rgba($primary, 0.8);
    }
  }

  .DateRangePicker__CalendarHighlight {
    border-color: $primary;
  }

  .daterange-picker-options {
    border-right: 1px solid #f4f5f6;
    z-index: 999;
    ul {
      list-style-type: none;
      min-width: 100px;
      padding: 8px 0;
    }
    li {
      cursor: pointer;
      padding: 8px 16px;
      white-space: nowrap;
      &:hover {
        background-color: #f6f7f9;
      }
    }
  }

  .container-calendar {
    min-width: 630px;
  }
}

.btn-daterange {
  background-color: $white !important;
  box-shadow: none !important;
  border-color: $gray-11;
  font-weight: normal;
  color: $body-color;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    transform: none;
    color: $body-color !important;
    border-color: $gray-11 !important;
    outline: none;
  }

  i {
    vertical-align: middle;
    margin-left: 0.5rem;
    color: $gray-400;
  }
}
