.dz-profile-img {
  .dropzone {
    width: 240px;
    height: 240px;
  }
}

input[name=email]{
  text-transform: lowercase;
}
