.tooltip-inner {
   max-width: 500px;
   padding: 0.25rem 0.5rem;
   color: black;
   justify-content: start;
   background-color: #f9b586;
   border-radius: 0.375rem;
   padding: 20px;
   border: 3px solid black; 
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); 
   text-align: left;
}