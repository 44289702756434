.btn-outline-primary:not(:disabled):not(.disabled) {
  &:hover,
  &:active {
    background-color: transparent;
    color: $body-color;
  }
}

.btn-outline-link,
.btn-outline-link:hover,
.btn-outline-link:active {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0;
  color: $nomads !important;
  font-weight: normal;
  transform: none;
}

.btn-gray {
  background-color: $gray-3;
  color: $white;
}

.btn-app-sm {
  padding: 0.25rem 1rem;
}
