.dashPMS-statistic-round {
  position: relative;
  width: 98px;
  height: 98px;
  border-radius: 50%;
  border: 1px dashed #c8c8c8;

  .dashPMS-statistic-round__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
  }
  .tit {
    padding-top: 1em;
    text-transform: uppercase;
    font-size: 14px;
    display: block;
    white-space: nowrap;
  }
  .num {
    font-weight: bold;
    display: block;
  }
}
.dashPMS-statistic {
  .tit {
    display: block;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .num {
    font-weight: bold;
    display: block;
    font-size: 25px;
  }
}
.pm-title {
  padding: 12px 0;
  cursor: pointer;
  .pm-title_box {
    display: inline-block;
    padding: 0 14px;
    white-space: nowrap;
    line-height: 1;
    border-left: 1px solid #c8c8c8;
    &:first-child {
      padding-left: 0;
      border-left: none;
    }
  }
}
.accommodation-card-pms {
  width: 160px;
  min-height: 160px;
  margin-right: 16px;
  background-color: #ddd;
  cursor: pointer;
  font-size: 14px;

  .accommodation-card-pms__title {
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 16px;
  }

  .accommodation-card-pms__task-pending {
    font-weight: bold;
    margin-bottom: 1em;
  }

  &.checkinCheckout {
    background-color: $primary;
  }
  &.checkin {
    background-color: $danger;
    color: #fff;
  }
  &.checkout {
    background-color: $success;
  }
  &.customerInside {
    background-color: $warning;
  }
  &.empty {
    background-color: $creme;
  }
}
