.color-picker {
  &-container {
    position: absolute;
    z-index: 1000;
    top: calc(#{$input-height} + 1.5rem)
  }

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.form-group-sm .color-picker-container {
  top: calc(#{$input-height-sm} + 1.5rem)
}

.form-group-lg .color-picker-container {
  top: calc(#{$input-height-lg} + 1.5rem)
}
