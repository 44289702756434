.hub {
  $header-height: 3.5rem;
  $footer-height: 3.5rem;
  $footer-props-height: 4.5rem;
  $whatsapp-icon-color: #0db860;
  $email-icon-color: #3da1ff;
  $sms-icon-color: #fda027;
  $all-icon-color: #868686;

  padding: 1rem;
  &-container {
    max-width: calc(100vw - 2rem);
    height: calc(
      100vh - #{$navbar-nav-height} - #{$navbar-subnav-height} - 2rem
    );
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 0 3px rgba($color: #000000, $alpha: 0.15);
    //
    display: grid;
    grid-template-columns: 480px 1fr 520px;
    grid-template-rows: 1fr;
  }

  &.for-crm {
    .hub-container {
      grid-template-columns: 500px 1fr;
    }
  }

  &-channel-list {
    display: flex;
    flex-direction: column;
    height: calc(
      100vh - #{$navbar-nav-height} - #{$navbar-subnav-height} - 2rem
    );
    border-right: 1px solid $gray-200;
    &-header {
      flex: 0 0 $header-height;
      border-bottom: 1px solid $gray-200;
      display: flex;

      align-items: center;

      &-filter {
        margin: 0;
        width: 100%;
        .input-group-text {
          border: 0;
          border-radius: 0;
        }

        .form-control {
          border: 0;
          border-radius: 0;
          height: auto;
          padding: 0 1rem;

          &::placeholder {
            font-size: $font-size-xsm;
            font-weight: 500;
            color: $gray-500;
            text-transform: uppercase;
          }
        }
      }
      &-switch-label {
        font-size: 11px;
        white-space: nowrap;
        cursor: pointer;
        display: block;
      }
    }
    &-body {
      flex: 1 1 auto;
      overflow-x: hidden;
      overflow-y: scroll;
      background-color: $gray-100;
    }
    &-item {
      padding: 0.8rem 1rem;
      border-bottom: 1px solid $gray-200;
      opacity: 0.7;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.6rem;

      &.is-active {
        background-color: #fff;
        box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.25);
        opacity: 1;
      }
      &.not-readed {
        background-color: #f9b68634;
      }

      &:hover {
        box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.25);
      }
    }
    &-alert {
      display: inline-block;
      min-width: 22px;
      line-height: 20px;
      text-align: center;
      background-color: #e66000;
      color: #fff;
      border-radius: 9999px;
      font-weight: bold;
      border: 1px solid #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
      padding: 0 2px;
      margin-left: 0.4em;
    }
  }
  &-message-list {
    display: flex;
    flex-direction: column;
    height: calc(
      100vh - #{$navbar-nav-height} - #{$navbar-subnav-height} - 2rem
    );
    border-right: 1px solid $gray-200;

    &-header {
      flex: 0 0 $header-height;
      border-bottom: 1px solid $gray-200;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      gap: 0.6rem;
    }
    &-body {
      flex: 1 1 auto;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 1rem 0;

      &-date {
        text-align: center;
        font-size: 12px;
        padding-top: 1rem;
      }
    }
    &-item {
      padding: 1rem;
    }
    &-footer {
      flex: 0 0 auto;
      border-top: 1px solid $gray-300;
      display: flex;
      align-items: center;
    }
  }
  .hub-message-body {
    display: flex;
    gap: 0.6rem;
    &-content {
      flex: 0 0 75%;
      background-color: $gray-300;
      padding: 0.3rem 0.5rem;
      border-radius: 0.4rem;
      font-size: 12px;

      &-name {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: bold;
        margin-bottom: 6px;
      }
      &-icon_source {
        font-size: 18px;
        line-height: 10px;
        height: 20px;
        &.whatsapp {
          //color: #0db860;
          color: $whatsapp-icon-color;
        }
        &.email {
          color: $email-icon-color;
        }
        &.sms {
          color: $sms-icon-color;
        }
      }

      &-date {
        padding-top: 0.3rem;
        text-align: right;
      }
    }
    &.to-right {
      flex-direction: row-reverse;
      .hub-message-body-content {
        background-color: $gray-700;
        color: #fff;
      }
    }
  }
  .hub-message-sender {
    position: relative;
    width: 100%;
    min-height: $footer-height;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: 1fr;

    gap: 0.6rem;
    padding: 0 0.6rem;
    align-items: center;

    .hub-message-sender-textarea {
      margin: 0;
      .form-control {
        padding: 0.6rem;
        border: none;
        border-radius: 0;
        field-sizing: content;
      }
    }

    &.loading-message:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #ffffff7a;
    }
  }
  &-source-selector {
    position: relative;
    &-button {
      display: flex;
      align-items: center;
      gap: 0.1rem;
      padding: 0.2rem 0.5rem;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;

      .caret {
        opacity: 0.4;
      }
    }

    .whatsapp-icon,
    .sms-icon,
    .email-icon,
    .all-icon {
      font-size: 20px;
    }
    .whatsapp-icon {
      color: $whatsapp-icon-color;
    }
    .email-icon {
      color: $email-icon-color;
    }
    .sms-icon {
      color: $sms-icon-color;
    }
    .all-icon {
      color: $all-icon-color;
    }

    .hub-source-selector-list {
      position: absolute;
      bottom: 100%;
      left: 0;
      min-width: 140px;
      background-color: #fff;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      padding: 0.2rem 0;
      z-index: 1;

      .hub-source-selector-list-item {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        padding: 0.2rem 0.5rem;
        cursor: pointer;

        &:hover {
          background-color: #eee;
        }
      }
    }

    &.is-disabled {
      opacity: 0.3;
    }
  }
  &-message-imagelink {
    max-width: 200px;
    display: block;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &-message-filelink {
    display: grid;
    place-content: center;
    width: 100px;
    height: 130px;
    font-weight: bold;
    font-size: 25px;
  }
  &-message-imagelink,
  &-message-filelink {
    background-color: #fff;
    border: 1px solid $gray-400;
    box-shadow: 0 1px 4px rgba($color: #000000, $alpha: 0.15);
    transition: box-shadow 0.15s;

    &:hover {
      box-shadow: 0 2px 8px rgba($color: #000000, $alpha: 0.2);
    }
  }
  &-props {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(
      100vh - #{$navbar-nav-height} - #{$navbar-subnav-height} - 2rem
    );

    &.app-component-loading {
      &::before {
        height: 100%;
      }
      &::after {
        top: 40%;
      }
    }

    &-header {
      flex: 0 0 $header-height;
      border-bottom: 1px solid $gray-200;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      gap: 0.6rem;
    }
    &-body {
      flex: 1 1 auto;
      overflow-x: hidden;
      overflow-y: auto;
      //padding-bottom: $footer-props-height;
    }
    &-footer {
      width: 100%;
      background-color: #fff;
      border-top: 1px solid $gray-200;
      flex: 0 0 $footer-height;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
