.logo {
  margin: 0 auto;

  img {
    width: 9rem;
    height: auto;
  }
}

.table-thumbnail-container {
  width: 150px;
  height: 150px;
  background-color: #eee;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
