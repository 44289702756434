.text-xsm {
  font-size: $font-size-xsm;
}

.text-2xsm {
  font-size: $font-size-2xsm;
}

.text-md {
  font-size: $font-size-base;
}

.text-transform-none {
  text-transform: none !important;
}

.help-text {
  color: #7c7c7c;
  font-size: 0.8rem;
  margin-top: -10px;
  margin-bottom: 10px;
  display: block;
}

.text-bold {
  font-weight: 700;
}
.text-normal {
  font-weight: normal;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 18px;
}
.small {
  font-size: 14x;
}
.fz-lead {
  font-size: 20px;
}
.text-small {
  font-size: 11px;
}
