.react-tel-input {
  width: 100%;
}

.input-group-merge {
  .react-tel-input .form-control:not(:last-child) {
    border-right: 1px solid $input-border-color;
    padding-right: $input-padding-x;

    &.is-invalid {
      border-right-color: $form-feedback-invalid-color;
    }

    &.form-control-sm {
      padding-right: $input-padding-x-sm;
    }

    &.form-control-lg {
      padding-right: $input-padding-x-lg;
    }
  }

  &.focused .react-tel-input .form-control {
    border-right-color: $input-focus-border-color;
  }
}
