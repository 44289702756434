.form-control-label {
  font-weight: $font-weight-bold;
  line-height: 1;
  margin-bottom: 12px;
  font-size: 18px;
  color: $body-color;
  // margin-bottom: 0;
}

.form-control-label-disabled {
  font-weight: $font-weight-bold;
  line-height: 1;
  margin-bottom: 12px;
  font-size: 18px;
  color: $gray-500;
}

.form-group.is-invalid .invalid-feedback {
  display: block;
}
.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
.input-group .rdt .form-control.is-invalid {
  border-color: $danger;
}
.invalid-feedback {
  color: $danger;
}

.input-group {
  .is-invalid {
    .input-group-text {
      border-color: $form-feedback-invalid-color;
      color: $form-feedback-invalid-color;
    }
  }

  &-prepend {
    .input-group-text {
      border-right: 0;
    }
  }
}

.focused .is-invalid {
  border-color: $form-feedback-invalid-color !important;
}

.form-control-xsm {
  height: $input-height-xsm;
  padding: $input-padding-y-xsm $input-padding-x-xsm;
  font-size: $font-size-xsm;
  line-height: $input-line-height-xsm;
  @include border-radius($input-border-radius-sm);
}

.input-mx-number {
  max-width: calc(#{$font-size-base} * 5);
}

.input-text-uppercase {
  input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }
}
