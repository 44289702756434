@font-face {
  font-family: 'fontello';
  src: url('../../../fonts/fontello/fontello.eot?17030031');
  src: url('../../../fonts/fontello/fontello.eot?17030031#iefix') format('embedded-opentype'),
  url('../../../fonts/fontello/fontello.woff2?17030031') format('woff2'),
  url('../../../fonts/fontello/fontello.woff?17030031') format('woff'),
  url('../../../fonts/fontello/fontello.ttf?17030031') format('truetype'),
  url('../../../fonts/fontello/fontello.svg?17030031#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?17030031#fontello') format('svg');
  }
}
*/

[class^="app-icon-"]:before, [class*=" app-icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1rem;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.app-icon-manager:before { content: '\e800'; } /* '' */
.app-icon-euro:before { content: '\e801'; } /* '' */
.app-icon-comment:before { content: '\e802'; } /* '' */
.app-icon-attachment:before { content: '\e803'; } /* '' */
.app-icon-warning:before { content: '\e804'; } /* '' */
.app-icon-circle-o:before { content: '\e805'; } /* '' */
.app-icon-note:before { content: '\e806'; } /* '' */
.app-icon-double-chevron-up:before { content: '\e807'; } /* '' */
.app-icon-chevron-up:before { content: '\e808'; } /* '' */
.app-icon-check:before { content: '\e809'; } /* '' */
.app-icon-clock-circular-outline:before { content: '\e80a'; } /* '' */
.app-icon-circle-minus:before { content: '\e80b'; } /* '' */
.app-icon-close:before { content: '\e80c'; } /* '' */
.app-icon-plus:before { content: '\e80d'; } /* '' */
.app-icon-sync:before { content: '\e80e'; } /* '' */
.app-icon-map-pin:before { content: '\e80f'; } /* '' */
.app-icon-multiple-users:before { content: '\e810'; } /* '' */
.app-icon-shield-star:before { content: '\e811'; } /* '' */
.app-icon-settings:before { content: '\e812'; } /* '' */
.app-icon-flash:before { content: '\e813'; } /* '' */
.app-icon-dollar:before { content: '\e814'; } /* '' */
.app-icon-list:before { content: '\e815'; } /* '' */
.app-icon-plane:before { content: '\e816'; } /* '' */
.app-icon-rocket:before { content: '\e817'; } /* '' */
.app-icon-locker:before { content: '\e818'; } /* '' */
.app-icon-unassigned:before { content: '\e819'; } /* '' */
.app-icon-chevron-down:before { content: '\e81a'; } /* '' */
.app-icon-file-copy:before { content: '\e81b'; } /* '' */
.app-icon-dashboards:before { content: '\e81c'; } /* '' */
.app-icon-address:before { content: '\e81d'; } /* '' */
.app-icon-star:before { content: '\e81e'; } /* '' */
.app-icon-plus-circle:before { content: '\e81f'; } /* '' */
.app-icon-double-chevron-down:before { content: '\e820'; } /* '' */
.app-icon-support:before { content: '\e821'; } /* '' */
.app-icon-analysis:before { content: '\e822'; } /* '' */
.app-icon-bookings:before { content: '\e823'; } /* '' */
.app-icon-content:before { content: '\e824'; } /* '' */
.app-icon-pms:before { content: '\e825'; } /* '' */
.app-icon-resources:before { content: '\e826'; } /* '' */
.app-icon-operation:before { content: '\e827'; } /* '' */
.app-icon-arrow-down:before { content: '\e828'; } /* '' */
.app-icon-marker:before { content: '\e829'; } /* '' */
.app-icon-request:before { content: '\e82a'; } /* '' */
.app-icon-task:before { content: '\e82b'; } /* '' */
.app-icon-calendar:before { content: '\e82c'; } /* '' */
.app-icon-areas:before { content: '\e82d'; } /* '' */
.app-icon-accommodation:before { content: '\e82e'; } /* '' */
.app-icon-properties:before { content: '\e82f'; } /* '' */
.app-icon-providers:before { content: '\e830'; } /* '' */
.app-icon-shift:before { content: '\e831'; } /* '' */
.app-icon-team:before { content: '\e832'; } /* '' */
.app-icon-resource:before { content: '\e833'; } /* '' */
.app-icon-phone-app:before { content: '\e834'; } /* '' */
.app-icon-profit-breakdown:before { content: '\e835'; } /* '' */
.app-icon-expenses:before { content: '\e836'; } /* '' */
.app-icon-email-template:before { content: '\e837'; } /* '' */
.app-icon-puzzle:before { content: '\e838'; } /* '' */
.app-icon-appliance:before { content: '\e839'; } /* '' */
.app-icon-custom-fields:before { content: '\e83a'; } /* '' */
.app-icon-roles:before { content: '\e83b'; } /* '' */
.app-icon-pdf:before { content: '\e83c'; } /* '' */
.app-icon-app:before { content: '\e83d'; } /* '' */
.app-icon-post:before { content: '\e83e'; } /* '' */
.app-icon-categories:before { content: '\e83f'; } /* '' */
.app-icon-rules:before { content: '\e840'; } /* '' */
.app-icon-icon-access-control:before { content: '\e841'; } /* '' */
.app-icon-rocket-2:before { content: '\e846'; } /* '' */
