.shift {
  &-slot-dnd {
    background-color: transparent;
    color: $primary;
    border: 5px dashed $gray-4;
    font-size: $font-size-xsm;
  }

  &-resource-avatar, &-resource-avatar img {
    @include avatar(120px);
    border-radius: 50%;
  }

  &-resource-status {
    &::after {
      width: 10px;
      height: 10px;
      content: ' ';
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 15px;
    }

    &-pending::after {
      background-color: $yellow-2;
    }

    &-accepted::after {
      background-color: $green-4;
    }

    &-rejected::after {
      background-color: $red-4;
    }
  }

  &-status{
    padding: 3px 5px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 3px;

    &-pending{
      background-color: #E9D42B;
    }

    &-accepted{
      background-color: #5EDD60;
    }

    &-rejected {
      background-color: #E96262;
    }
    
    &-not-assigned{
      background-color: #E96262;
    }
  }
}
