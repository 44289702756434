.dashboard {
  &-circle-info {
    background-color: $white;
    width: 145px;
    height: 145px;
    border-radius: 50%;
    border: 5px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    &-main-stats {
      font-size: 2.5rem;
    }
  }

  &-info-card {
    height: 530px;
    overflow: auto;

    .avatar {
      background-color: $dark-2;
    }
  }

  &-card-title {
    position: relative;
    overflow: hidden;
    height: 120px;
    margin-bottom: 15px;

    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      width: 8px;
      background-color: #ccc;
    }

    .card-body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .tit {
      white-space: nowrap;
      font-size: 26px;
      line-height: 1;
      .th-icon {
        font-size: 24px;
        line-height: 16px;
        vertical-align: middle;
        margin-left: 9px;
        position: relative;
        top: -5px;
      }
    }
    .num {
      font-size: 35px;
      line-height: 1;
      font-weight: bold;
    }

    .task-box {
      border-left: 1px solid #e7e7e7;
      padding: 0 10px;
      .task-box-value {
        font-weight: bold;
        font-size: 20px;
        line-height: 1;
        padding-top: 5px;
      }
    }

    .c-task {
      &:first-child {
        .task-box {
          border-left: none;
          padding-left: 0;
        }
      }
      &:last-child {
        .task-box {
          padding-right: 0;
        }
      }
    }

    &.in {
      &:before {
        background-color: $danger;
      }
    }
    &.out {
      &:before {
        background-color: $success;
      }
    }
    &.tasks {
      &:before {
        display: none;
      }
    }
  }

  &-info-card_book-tit {
    font-size: 26px;
    margin-bottom: 1em;

    .th-icon {
      margin-right: 8px;
      font-size: 24px;
      position: relative;
      top: 2px;
    }
  }
}
