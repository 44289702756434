.chat {
  margin: 1rem;

  &-container {
    height: calc(100vh - #{$navbar-nav-height} - #{$navbar-subnav-height} - 2rem);
    border-radius: 0;
    box-shadow: none;
    margin: 0;
  }

  &-channels {

    &-filter {
      border-bottom: 1px solid $gray-200;

      .input-group-text {
        border: 0
      }

      .form-control {
        border: 0;

        &::placeholder {
          font-size: $font-size-xsm;
          font-weight: 500;
          color: $gray-500;
          text-transform: uppercase;
        }
      }
    }

    &-tabs {
      text-transform: uppercase;
      font-size: $font-size-2xsm;
      font-weight: 500;
      color: $gray-500;

      .nav-link {
        padding: $btn-padding-y;
        cursor: pointer;

        &:hover, &.active {
          color: $nomads !important;
        }
      }
    }

    &-item {
      border-left: 3px solid $nomads;

      &-active {

        .chat-channels-item-content {
          background-color: $white;
          position: relative;
          box-shadow: 1px 3px 9px 0 rgba(0, 0, 0, 0.17);
        }
      }

      &-border-red {
        border-left-color: $chat-client-red;

        .chat-channels-item-circle {
          background: $chat-client-red;
        }
      }

      &-border-blue {
        border-left-color: $chat-client-blue;

        .chat-channels-item-circle {
          background: $chat-client-blue;
        }
      }

      &-content {
        padding: .8rem 1rem;
        background-color: $gray-100;
        border-bottom: 1px solid $gray-200;
      }

      &-circle {
        width: 9px;
        height: 9px;
        background: $nomads;
        border-radius: 50%;
        display: inline-block;
      }

      .avatar {
        background-color: $gray-400;
        color: $white !important;
        font-weight: bold;
        font-size: $font-size-lg;
      }
    }
  }

  &-messages {
    @include media-breakpoint-up(md) {
      border-left: 1px solid $gray-200;
      border-right: 1px solid $gray-200;
    }

    .card-header {
      min-height: calc(#{$input-height-lg} + 1px);
      border-bottom-color: $gray-200;
      padding: 0;
    }

    .card-body {
      overflow: auto;
      display: flex;
      flex-direction: column-reverse;
    }

    .card-footer {
      .input-group-text, .form-control {
        border: 0;
      }
    }

    &-message {

      &-container {
        max-width: 60%;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }

      &-right {
        display: flex;
        justify-content: flex-end;

        .chat-messages-message-content {
          background-color: $chat-message-user;
          color: $white;
        }

        @include media-breakpoint-up(md) {
          &-margin {
            margin-right: calc(48px + 0.5rem);
          }
        }
      }

      &-left {
        display: flex;

        .chat-messages-message-content {
          background-color: $gray-300;

          .chat-messages-message-time {
            color: $gray-600;
          }
        }

        @include media-breakpoint-up(md) {
          &-margin {
            margin-left: calc(48px + 0.5rem);
          }
        }
      }

      &-date {
        font-size: $font-size-sm;
        color: $gray-600;
        text-align: center;
        margin-bottom: .8rem;
        margin-top: .8rem;
      }

      &-username {
        font-weight: 500;
        font-size: $font-size-sm;
        color: $gray-700;
      }

      &-content {
        margin: 0;
        border-radius: 5px;
        font-weight: 300;
        font-size: $font-size-sm;
        padding-top: .3125rem;
        padding-bottom: .3125rem;

        .chat-messages-message-time {
          text-align: right;
          font-size: $font-size-2xsm;
        }
      }

      &-text {
        word-break: break-word;
      }

      &-image img {
        padding-top: 0.6rem;
        max-width: 100%;
        max-height: 200px;
      }
    }
    .bot{
      background-color: #f6f6c3;
    }
  }

  &-overview {

    .card-header {
      min-height: calc(#{$input-height-lg} + 1px);
      height: calc(#{$input-height-lg} + 1px);
      border-bottom-color: $gray-200;
    }

    .card-body {
      overflow: auto;
    }

    .divider {
      border-top-color: $gray-200;
    }

    .app-separator {
      .circle-primary-small, .circle-outline-default-small {
        width: 7px;
        height: 7px;
      }

      &-line {
        min-width: 12px;
      }

      &-default-line {
        min-width: 22px;
      }
    }
  }

  &-create-channel {
    padding: 0.53rem 1.5rem;
    text-align: center;
  }

  &-member {
    .chat-member-delete {
      cursor: pointer;
      margin-top: -5px;
    }

    &:hover {
      background-color: $gray-4;

      .chat-member-delete {
        display: block !important;
      }
    }
  }
}
