.footer {
  .copyright {
    font-size: $font-size-xs;
  }
}

.auth-footer {
  height: $navbar-nav-height;
  background-color: white;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  box-shadow: 0 -1px 1px 0 rgba(0,0,0,0.10);
}
