::-webkit-scrollbar {
  width: 0.6em;
}

::-webkit-scrollbar-track {
  background-color: #ddd;
}

::-webkit-scrollbar-thumb {
  background-color: desaturate($primary, 50);
  outline: none;
}
