.dc_container {
  $entity_height: 8px;

  font-size: 14px;
  text-align: center;
  position: relative;
  width: 100%;
  overflow: scroll;

  table {
    width: 100%;
  }

  tbody {
    display: block;
  }

  .dc_title {
    font-weight: bold !important;
  }

  .ic-calendar {
    display: none;
    //font-size: 10px;
  }

  tr {
    white-space: nowrap;
    background-color: white;
    //  border-bottom: 1px solid white;
    display: flex;
  }

  td,
  th {
    width: 110px;
    // overflow: hidden;
    display: inline-block;
  }
  th {
    padding: 4px 8px;
    border-right: 1px solid $gray-13;
  }
  td {
    border-right: 1px solid $gray-13;
    padding: 0px;
    flex: 1;
  }

  .dc_sidebar_cell {
    width: 200px;
    position: sticky;
    left: 0;
    text-align: left;
    background-color: $creme;
    border-right: 1px solid $gray-13;
    z-index: 2;
    font-weight: normal;
    overflow: hidden;
    font-size: 12px;
    &.dc_sidebar_cell_shift {
      font-size: 15px;
    }

    &.large {
      width: 300px;
    }

  }

  .dc-border-top {
    border-top: 1px solid $gray-13;
  }

  .dc_date-sticky {
    position: sticky;
    left: 200px;
    background-color: white;

    &.large{
      left: 300px;
    }
  }

  .dc_date-start {
    margin-right: 50px;
  }

  .dc_bg-primary {
    background-color: $creme;
    border-right: 1px solid $gray-13;
  }

  .dc_data-empty,
  .no-border-right {
    border-right: none;
  }

  .dc_bg-white {
    background-color: white !important;
  }

  .dc_data-row {
    background-color: $creme;
  }
  .dc_data-row td {
    background-color: transparent;
  }

  .border_round-right {
    border-top-right-radius: 75px;
    border-bottom-right-radius: 75px;
  }

  .border_round-left {
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    margin-left: 5px;
  }

  .dc_entity_no-draggable {
    margin: 2px 0;
    height: $entity_height;
    position: relative;
    width: 100%;

    .dc_delete_icon {
      display: none;
    }
    :hover {
      .dc_delete_icon {
        display: block;
        cursor: default;
      }
    }
  }

  .dc_entity_no-draggable div {
    width: 102%;
    height: $entity_height;
    display: inline-flex;
    // align-items: center;
    span {
      z-index: 1;
    }
  }

  .cal-booking-line {
    background-color: #ddd;

    &.color-past {
      background-color: $danger;
    }
    &.color-present {
      background-color: $warning;
    }
    &.color-future {
      background-color: $success;
    }
    &.color-blocked {
      background-color: orange;
    }
  }

  .dc_entity {
    min-width: 100%;
    white-space: normal;
    text-align: left;
    margin: 2px 4px;
    font-size: 12px;
    .dc_delete_icon {
      display: none;
    }
    :hover {
      .dc_delete_icon {
        display: block;
        cursor: default;
      }
    }
  }

  .dc_entity span {
    margin-right: 4px;
  }

  .chevron {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}

.dc_toolbar {
  .custom-select__menu {
    z-index: 3 !important;
    overflow-x: scroll;
  }
  //
  .dc_toolbar_item {
    padding: 0 5px;
    // flex: 1 1 auto;
    // margin: 0 8px;
    // max-width: 150px;
    // min-width: 100px;
  }
}

.help-modal-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  .border_round-right {
    border-top-right-radius: 75px;
    border-bottom-right-radius: 75px;
  }

  .border_round-left {
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    margin-left: 5px;
  }
  
  .cal-booking-line {
    background-color: #ddd;
  
    &.color-past {
      background-color: $danger;
      color: $danger;
    }
    &.color-present {
      background-color: $warning;
      color: $warning;
    }
    &.color-future {
      background-color: $success;
      color: $success;
    }
    &.color-blocked {
      background-color: orange;
      color: orange;
    }
  }
}
