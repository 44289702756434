@font-face {
  font-family: "threeangular";
  src: url("../../fonts/threeangular/threeangular.eot?t=1628947810938"); /* IE9*/
  src: url("../../fonts/threeangular/threeangular.eot?t=1628947810938#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../fonts/threeangular/threeangular.woff2?t=1628947810938")
      format("woff2"),
    url("../../fonts/threeangular/threeangular.woff?t=1628947810938")
      format("woff"),
    url("../../fonts/threeangular/threeangular.ttf?t=1628947810938")
      format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url("../../fonts/threeangular/threeangular.svg?t=1628947810938#threeangular")
      format("svg"); /* iOS 4.1- */
}

[class^="th-icon-"],
[class*=" th-icon-"] {
  font-family: "threeangular" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.th-icon-arrow-right:before {
  content: "\ea01";
}
.th-icon-calendar:before {
  content: "\ea02";
}
.th-icon-comment:before {
  content: "\ea03";
}
.th-icon-cross:before {
  content: "\ea04";
}
.th-icon-diamond:before {
  content: "\ea05";
}
.th-icon-ellipse:before {
  content: "\ea06";
}
.th-icon-filters:before {
  content: "\ea07";
}
.th-icon-in:before {
  content: "\ea08";
}
.th-icon-notification:before {
  content: "\ea09";
}
.th-icon-out:before {
  content: "\ea0a";
}
.th-icon-plus:before {
  content: "\ea0b";
}
.th-icon-rectangle:before {
  content: "\ea0c";
}
.th-icon-settings:before {
  content: "\ea0d";
}
.th-icon-triangle:before {
  content: "\ea0e";
}
// Changes
.th-icon-calendar {
  font-size: 20px;
  line-height: 16px;
}
